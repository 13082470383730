import React, { useState } from 'react';
import { Button, RaRecord } from 'react-admin';
import { useParams } from 'react-router-dom';
import PrintIcon from '../../../components/shared/icons/PrintIcon';
import { PDF_RESULTS_NAME } from '../../../core/constants/efficiency-test-pdf.constants';
import {
  getResultsDocx,
  getResultsPdf
} from '../../../core/services/effieiciency-test-api.service';
import { downloadFile } from '../../../core/utils/file-utils';
import { EfficiencyTestsDownloadFiltersDialog } from './EfficiencyTestsDownloadFiltersDialog';
import {
  DocumentType,
  DocumentTypeConfig
} from '../../../core/models/document-type/documentType';
import { Language } from '../../../core/models/language/language';

interface Props {
  disabled: boolean;
  efficiencyTest: RaRecord;
}

export const EfficiencyTestsDownloadResultsPdfButton = (props: Props) => {
  const [showDownloadFiltersDialog, setShowDownloadFiltersDialog] =
    useState<boolean>(false);
  const params = useParams();
  const { disabled, efficiencyTest } = props;

  const handleDownloadFiltersDialog = () => setShowDownloadFiltersDialog(true);

  const handleDownload = async (languageCode: string, documentType: string) => {
    const { exportType, extension } = DocumentTypeConfig[documentType];
    const { extension: languageExtension } = Language[languageCode];
    let response;

    if (documentType === DocumentType.PDF) {
      response = await getResultsPdf(params.efficiencyTestId, languageCode);
    } else {
      response = await getResultsDocx(params.efficiencyTestId, languageCode);
    }

    const base64Response = await fetch(`${exportType},${response.data.file}`);
    const blob = await base64Response.blob();
    downloadFile(
      blob,
      `${
        efficiencyTest.identifier ? efficiencyTest.identifier + '_' : ''
      }${PDF_RESULTS_NAME}${languageExtension}${extension}`
    );
  };

  return (
    <>
      <Button
        startIcon={<PrintIcon />}
        variant="outlined"
        size="medium"
        label={'resources.efficiency-tests.fields.downloadResultsPdf'}
        disabled={disabled}
        onClick={handleDownloadFiltersDialog}
      />
      <EfficiencyTestsDownloadFiltersDialog
        showDownloadFiltersDialog={showDownloadFiltersDialog}
        setShowDownloadFiltersDialog={setShowDownloadFiltersDialog}
        handleDownload={handleDownload}
      />
    </>
  );
};
