import React from 'react';
import { useLocaleState, useTranslate } from 'react-admin';
import { Result } from '../../../../core/models/efficiency-tests/result';
import {
  round,
  roundUp,
  toLocaleString
} from '../../../../core/utils/number-utils';

interface Props {
  results: Result[];
}

export const EfficiencyTestResultsMainTable = (props: Props) => {
  const t = useTranslate();
  const { results } = props;
  const [locale] = useLocaleState();

  return (
    <>
      <table className="custom-table results-table">
        <thead>
          <tr>
            <th colSpan={2}>
              {t('resources.efficiency-tests.fields.suctionPressure')}
            </th>
            <th colSpan={3}>{t('resources.efficiency-tests.fields.flow')}</th>
            <th colSpan={3}>
              {t('resources.efficiency-tests.fields.absorbedPower')}
            </th>
          </tr>
          <tr>
            <th>{t('resources.efficiency-tests.fields.engineSide')}</th>
            <th>{t('resources.efficiency-tests.fields.blindSide')}</th>
            <th>{t('resources.efficiency-tests.fields.measurement')}</th>
            <th>{t('resources.efficiency-tests.fields.curve')}</th>
            <th>{t('resources.efficiency-tests.fields.difference')}</th>
            <th>{t('resources.efficiency-tests.fields.measurement')}</th>
            <th>{t('resources.efficiency-tests.fields.curve')}</th>
            <th>{t('resources.efficiency-tests.fields.difference')}</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result) => (
            <tr key={crypto.randomUUID()}>
              <td>
                {toLocaleString(round(result.suctionPressure.lt, 0), locale)}
              </td>
              <td>
                {toLocaleString(round(result.suctionPressure.lc, 0), locale)}
              </td>
              <td>
                {toLocaleString(roundUp(result.flow.measurement), locale)}
              </td>
              <td>{toLocaleString(result.flow.curve, locale)}</td>
              <td>{toLocaleString(result.flow.difference, locale)}%</td>
              <td>
                {toLocaleString(result.absorbedPower.measurement, locale)}
              </td>
              <td>{toLocaleString(result.absorbedPower.curve, locale)}</td>
              <td>
                {toLocaleString(result.absorbedPower.difference, locale)}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
