import {
  EXPORT_DOCX_DATA,
  EXPORT_PDF_DATA
} from '../../constants/file.constants';

export enum DocumentType {
  PDF = 'PDF',
  DOCX = 'DOCX'
}

export const DocumentTypeConfig = {
  [DocumentType.PDF]: {
    exportType: EXPORT_PDF_DATA,
    extension: '.pdf'
  },
  [DocumentType.DOCX]: {
    exportType: EXPORT_DOCX_DATA,
    extension: '.docx'
  }
};
