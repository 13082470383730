export const Language = {
  es: {
    extension: '_ES'
  },
  en: {
    extension: '_EN'
  },
  fr: {
    extension: '_FR'
  }
};
