import React from 'react';
import { Button, RaRecord } from 'react-admin';
import { useParams } from 'react-router-dom';
import PrintIcon from '../../../components/shared/icons/PrintIcon';
import { PDF_DATA_NAME } from '../../../core/constants/efficiency-test-pdf.constants';
import { getDataPdf } from '../../../core/services/effieiciency-test-api.service';
import { downloadFile } from '../../../core/utils/file-utils';
import { EXPORT_PDF_DATA } from '../../../core/constants/file.constants';

interface Props {
  disabled: boolean;
  efficiencyTest: RaRecord;
}

export const EfficiencyTestsDownloadDataPdfButton = (props: Props) => {
  const params = useParams();
  const { disabled, efficiencyTest } = props;

  const handle = async () => {
    const response = await getDataPdf(params.efficiencyTestId);

    const base64Response = await fetch(
      `${EXPORT_PDF_DATA},${response.data.file}`
    );
    const blob = await base64Response.blob();
    downloadFile(
      blob,
      `${
        efficiencyTest.identifier ? efficiencyTest.identifier + '_' : ''
      }${PDF_DATA_NAME}.pdf`
    );
  };

  return (
    <Button
      startIcon={<PrintIcon />}
      variant="outlined"
      size="medium"
      label={'resources.efficiency-tests.fields.downloadDataPdf'}
      disabled={disabled}
      onClick={handle}
    />
  );
};
