import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { getLanguages } from '../../../core/services/language-api.service';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box
} from '@mui/material';
import { getDocumentTypes } from '../../../core/services/document-type-api.service';

type Props = {
  showDownloadFiltersDialog: boolean;
  setShowDownloadFiltersDialog: (show: boolean) => void;
  handleDownload: (languageCode: string, documentType: string) => void;
};

export const EfficiencyTestsDownloadFiltersDialog = ({
  showDownloadFiltersDialog,
  setShowDownloadFiltersDialog,
  handleDownload
}: Props) => {
  const t = useTranslate();
  const [languageChoices, setLanguageChoices] = useState([]);
  const [documentTypeChoices, setDocumentTypeChoices] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>();

  useEffect(() => {
    getLanguageChoices();
    getDocumentTypeChoices();
  }, []);

  const getLanguageChoices = async () => {
    const languages = await getLanguages();
    const transformedLanguages = languages?.data.map((language: any) => {
      return { code: language?.code, label: language?.label };
    });
    setLanguageChoices(transformedLanguages);
    setSelectedLanguage(transformedLanguages[0]?.code);
  };

  const getDocumentTypeChoices = async () => {
    const documentTypes = await getDocumentTypes();
    const transformedDocumentTypes = documentTypes?.data.map(
      (documentType: any) => {
        return {
          type: documentType?.type,
          extension: documentType?.extension,
          label: documentType?.label
        };
      }
    );
    setDocumentTypeChoices(transformedDocumentTypes);
    setSelectedDocumentType(transformedDocumentTypes[0]?.type);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setSelectedLanguage(event.target.value as string);
  };

  const handleDocumentTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedDocumentType(event.target.value as string);
  };

  const handleConfirm = () => {
    handleDownload(selectedLanguage, selectedDocumentType);
    onCloseDownloadFiltersDialog();
  };

  const onCloseDownloadFiltersDialog = () => {
    setShowDownloadFiltersDialog(false);
  };

  return (
    <Dialog
      open={showDownloadFiltersDialog}
      onClose={onCloseDownloadFiltersDialog}
      fullWidth
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <FormControl fullWidth>
            <InputLabel>
              {t('resources.efficiency-tests.dialog.language')}
            </InputLabel>
            <Select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              label={t('resources.efficiency-tests.dialog.language')}
            >
              {languageChoices.map((language) => (
                <MenuItem key={language.code} value={language.code}>
                  {t(language.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>
              {t('resources.efficiency-tests.dialog.format')}
            </InputLabel>
            <Select
              value={selectedDocumentType}
              onChange={handleDocumentTypeChange}
              label={t('resources.efficiency-tests.dialog.format')}
            >
              {documentTypeChoices.map((documentType) => (
                <MenuItem key={documentType.type} value={documentType.type}>
                  {t(documentType.label, { extension: documentType.extension })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDownloadFiltersDialog} color="primary">
          {t('resources.efficiency-tests.dialog.actions.cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          disabled={!selectedLanguage}
        >
          {t('resources.efficiency-tests.dialog.actions.print')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
